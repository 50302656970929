body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141F2B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart-container {
  width: 100%;
  height: 300px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Teimer';
  color: #FFFFFF;
}


.list-group {
  border-radius: 0px !important;
}

.list-group-item {
  background-color: transparent;
  padding-left: 0px !important;
  border: 0px !important;
  border-bottom: 1px solid #D61FB5 !important;
  border-radius: 0px;
}

p,
div,
td {
  color: #FFFFFF;
  font-size: 12px;
  font-family: 'Teimer';
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

th {
  font-size: 12px;
  font-family: 'Teimer';
  color: rgba(255, 255, 255, 0.35);
}

tr>th {
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #d71ab1 !important;
  border-top: 0px !important;
  border-spacing: 0px;
}

tbody {
  border-top: 0px !important;
}

tr>td {
  border-bottom: 0px;
  border-top: 0px;
}

a {
  color: #14BAF0;
}

a:hover {
  color: #6cdaff;
}

.navbar-brand {
  font-size: inherit;
}

.underline {
  border-bottom: 1px solid #d71ab1 !important;
}

.dropdown-toggle {
  color: #FFFFFF;
}

.dropdown-toggle:hover,
.dropdown-toggle:visited,
.dropdown-toggle:active,
.dropdown-toggle:focus {
  color: #14BAF0;
}

.btn,
.form-control,
.dropdown-toggle,
.dropdown-item,
.nav-dropdown {
  border-radius: 0px;
  font-size: 15px;
  font-family: 'Liberation Sans';
}

.btn.secondary {
  color: #FFFFFF;
}


.filter {
  font-size: 1.25rem;
  color: #FFFFFF;
}

.filter input,
.filter input:focus {
  background: #141F2B;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-size: 12px;
}

.navbar .btn {
  font-size: 13px;
}

.navbar .btn.btn-success,
.navbar .btn.btn-success:active,
.navbar .btn.btn-success:hover,
.navbar .btn.btn-active:focus {
  background: #D61FB5;
  border: 0px !important;
  color: #FFFFFF;
}

.btn.btn-active:focus {
  box-shadow: rgba(214, 31, 181, 0.5) !important;
}

.filter .dropdown-toggle {
  color: #FFFFFF;
  font-size: 12px;
}

.filter .btn {
  font-size: 12px;
}

.navbar.navbar-dark {
  color: #FFFFFF !important;
}

.h2 .underline {
  border-bottom: 1px solid #d71ab1;
}

.placeholder {
  width: 32px;
  height: 32px;
  background-color: #CCCCCC;
}

.not_found {
  margin-top: 30vh;
}

.summary th {
  width: 20%;
}

.blue {
  color: #14C6FF;
  ;
}

.asset {
  width: 32px;
  height: 32px;
}

.red {
  color: #D6591F;
}

.grey {
  color: rgba(255, 255, 255, 0.5) !important;
}

.green {
  color: #6FD61F;
}

.pointer {
  cursor: pointer;
}

#nav-dropdown {
  font-size: 12px !important;
}

/** Secondary Navigation **/

.secondary-nav {
  background-color:  #23364A !important
}

.secondary-nav a {
  color: #FFFFFF !important  
}

.secondary-nav a.hover {
  text-decoration: underline;
}